<template>
  <BasePageContent>
    <WarehouseUpdate
      v-if="true"
      :uuid="uuid"
      @onsave="OnSave"
      @oncancel="OnCancel"
      cancel-text="Cancel"
    />
  </BasePageContent>
</template>

<script>
import WarehouseUpdate from "@/components/ui/warehouse/WarehouseUpdate.vue";
export default {
  components: { WarehouseUpdate },
  props: {
    uuid: {
      type: String,
    },
  },
  setup() {
    const OnSave = () => {
      // router.replace({ name: "warehouse-detail", params: { uuid: props.uuid } });
    };
    // const router = useRouter();
    const OnCancel = () => {
      // router.replace({ name: "warehouse-detail", params: { uuid: props.uuid } });
    };
    return {
      OnSave,
      OnCancel,
    };
  },
};
</script>